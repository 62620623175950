import type React from "react"
import { useEffect, useRef } from "react"
import { useMap } from "react-leaflet"
import L from "leaflet"
import appSvgUrl from "../../../assets/images/location.png"
// import { geolocationService } from "../../../services"
import useCurrentLocation from "../../../hooks/useCurrentLocation"
import { useTheme } from "@mui/material"
import useDeviceOrientation from "../../../hooks/useDeviceOrientation"

interface IProps {
  showAccuracy?: boolean
  showOrientation?: boolean
  fovAngle?: number // FOV angle in degrees
  fovRange?: number // FOV range in meters
}

const UserLocation: React.FC<IProps> = (props: IProps) => {
  const map = useMap()
  const theme = useTheme()
  const marker = useRef<L.Marker | null>(null)
  const accuracyCircle = useRef<L.Circle | null>(null)
  const fovPolygon = useRef<L.Polygon | null>(null)
  const { currentLocation, error } = useCurrentLocation()
  const { alpha: orientationAngle } = useDeviceOrientation()

  const calculateFOVPoint = (start: { lat: number; lng: number }, bearing: number, distance: number) => {
    const earthRadius = 6371000 // Earth radius in meters
    const lat1 = start.lat * (Math.PI / 180)
    const lon1 = start.lng * (Math.PI / 180)
    const angularDistance = distance / earthRadius
    const lat2 = Math.asin(
      Math.sin(lat1) * Math.cos(angularDistance) + Math.cos(lat1) * Math.sin(angularDistance) * Math.cos(bearing),
    )
    const lon2 =
      lon1 +
      Math.atan2(
        Math.sin(bearing) * Math.sin(angularDistance) * Math.cos(lat1),
        Math.cos(angularDistance) - Math.sin(lat1) * Math.sin(lat2),
      )
    return L.latLng(lat2 * (180 / Math.PI), lon2 * (180 / Math.PI))
  }

  useEffect(() => {
    if (currentLocation.latitude && currentLocation.longitude && !error) {
      const CurrentlatLng: L.LatLngExpression = [currentLocation.latitude, currentLocation.longitude]
      if (!marker.current) {
        const userLocationIcon = new L.Icon({
          iconUrl: appSvgUrl,
          iconSize: [24, 24],
          iconAnchor: [12, 12],
          popupAnchor: [0, -32],
        })
        marker.current = L.marker([currentLocation.latitude, currentLocation.longitude], {
          icon: userLocationIcon,
        }).addTo(map)
      } else {
        marker.current.setLatLng([currentLocation.latitude, currentLocation.longitude])
      }

      if (props.showAccuracy && currentLocation.accuracy !== null) {
        if (accuracyCircle.current) {
          accuracyCircle.current
            .setLatLng([currentLocation.latitude, currentLocation.longitude])
            .setRadius(currentLocation.accuracy)
        } else {
          accuracyCircle.current = L.circle([currentLocation.latitude, currentLocation.longitude], {
            radius: currentLocation.accuracy,
            color: "transparent",
            fillColor: theme.palette.secondary.main,
            fillOpacity: 0.2,
          }).addTo(map)
        }
      }

      if (props.showOrientation && orientationAngle !== null && props.fovAngle && props.fovRange) {
        const orientationRad = (orientationAngle * Math.PI) / 180 // Convert orientation to radians
        const latLngObj = L.latLng(CurrentlatLng)
        const point1 = latLngObj // User's current location
        const point2 = calculateFOVPoint(
          { lat: currentLocation.latitude, lng: currentLocation.longitude },
          orientationRad - (props.fovAngle / 2) * (Math.PI / 180),
          props.fovRange,
        )
        const point3 = calculateFOVPoint(
          { lat: currentLocation.latitude, lng: currentLocation.longitude },
          orientationRad + (props.fovAngle / 2) * (Math.PI / 180),
          props.fovRange,
        )

        if (fovPolygon.current) {
          fovPolygon.current.setLatLngs([point1, point2, point3])
        } else {
          //! Change these values to match the theme
          fovPolygon.current = L.polygon([point1, point2, point3], {
            color: "red",
            fillColor: "red",
            fillOpacity: 1,
          }).addTo(map)
        }
      }
    }
  }, [currentLocation, map, marker])

  return null
}

// UserLocation.defaultProps = {
//   fovAngle: 90,
//   fovRange: 20,
// }

export default UserLocation
