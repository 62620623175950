import { ThemeProvider, Stack, Box, IconButton, createTheme } from "@mui/material"
import React from "react"
import { VscClose } from "react-icons/vsc"
import { lightColors } from "../../../constants"

interface IProps {
  avatar?: React.ReactNode
  trailing?: React.ReactNode
  leading?: React.ReactNode
  onClose: () => void
}

function SheetTopBar({ avatar, trailing, leading, onClose }: IProps) {
  const darkTheme = createTheme({ palette: { mode: "dark", ...lightColors } })

  return (
    <ThemeProvider theme={darkTheme}>
      <Stack position={"sticky"} top={0} zIndex={50}>
        <Box
          sx={{
            px: 2,
            py: 1,
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-between",
            background: "linear-gradient(-90deg, #0c355f 2%, rgba(26,47,68,1) 62%);",
            backdropFilter: "blur(10px)",
          }}>
          <Stack flexDirection={"row"} alignItems={"center"} gap={1.5}>
            {avatar && avatar}
            {leading && leading}
          </Stack>
          <Stack gap={1} alignItems={"center"} flexDirection={"row"}>
            {trailing && trailing}
            <IconButton onClick={onClose}>
              <VscClose />
            </IconButton>
          </Stack>
        </Box>
      </Stack>
    </ThemeProvider>
  )
}

export default SheetTopBar
