import React, { useCallback, useEffect, useState } from "react"
import { useMap } from "react-leaflet"
import { CircularProgress, Fab, Tooltip } from "@mui/material"

import { geolocationService } from "../../../services/geolocation.service"
import { HiOutlineUser } from "react-icons/hi"

function MapCurrentLocationButton({ fetchOnInit }: { fetchOnInit?: boolean }) {
  const [locating, setLocating] = useState<boolean>(false)
  const map = useMap()

  const fetchLocation = useCallback(() => {
    setLocating(true)
    geolocationService.getCurrentLocation((position) => {
      if (!position) return
      map.flyTo([position.coords.latitude, position.coords.longitude])
      setLocating(false)
    })
  }, [map])

  useEffect(() => {
    if (fetchOnInit) fetchLocation()
  }, [fetchLocation, fetchOnInit])

  return (
    <Tooltip title="My Location">
      <Fab style={{ backgroundColor: "rgba(0,0,0,0.5)", boxShadow: "none" }} onClick={() => fetchLocation()}>
        {locating ? <CircularProgress sx={{ color: "white" }} size={18} /> : <HiOutlineUser size={24} color="white" />}
      </Fab>
    </Tooltip>
  )
}

export default MapCurrentLocationButton
