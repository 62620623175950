import {
  Button,
  Divider,
  ListItemButton,
  Stack,
  Typography,
  List,
  useTheme,
  Paper,
  CircularProgress,
} from "@mui/material"
import type { Feature } from "@turf/helpers"
import React, { useContext, useState } from "react"
import { HiOutlineTrash } from "react-icons/hi"
import LayoutContext from "../../../contexts/layout.context"
import FeaturePropertiesSheet from "./FeaturePropertiesSheet.component"

function FeatureActionSheet({
  onDelete,
  onEdit,
  onMove,
  onStart,
  onEnd,
  ...props
}: {
  feature: Feature
  onDelete: (feature: Feature) => void
  onEdit: (feature: Feature) => void
  onMove: (feature: Feature) => void
  onStart: (feature: Feature) => void
  onEnd: (feature: Feature) => void
}) {
  const [feature] = useState<Feature>(props.feature)
  const theme = useTheme()
  const { closeActionSheet } = useContext(LayoutContext)
  const [isDeleting, setIsDeleting] = useState(false)
  const [showProperties, setShowProperties] = useState<Boolean>(false)

  if (showProperties)
    return (
      <FeaturePropertiesSheet
        feature={feature}
        onBack={() => setShowProperties(false)}
        onEdit={() => onEdit(feature)}
      />
    )

  return (
    <Stack width={"100%"} height={"100%"} p={2} gap={1.5}>
      <Paper sx={{ borderRadius: 3.5, overflow: "hidden" }}>
        <Stack width={"100%"} justifyContent={"center"} bgcolor={theme.palette.grey[100]} p={1.2}>
          <Typography textAlign={"center"} fontSize={12} color={theme.palette.grey[700]}>
            Choose Action
          </Typography>
        </Stack>
        <Divider />
        <List disablePadding>
          <ListItemButton divider sx={{ padding: 1.5 }} onClick={() => setShowProperties(true)}>
            <Typography width={"100%"} fontSize={16} textAlign={"center"}>
              View Properties
            </Typography>
          </ListItemButton>
          <ListItemButton
            divider
            sx={{ padding: 1.5 }}
            onClick={() => {
              onStart(feature)
              closeActionSheet()
            }}>
            <Typography width={"100%"} fontSize={16} textAlign={"center"}>
              Start from here
            </Typography>
          </ListItemButton>
          <ListItemButton
            divider
            sx={{ padding: 1.5 }}
            onClick={() => {
              onEnd(feature)
              closeActionSheet()
            }}>
            <Typography width={"100%"} fontSize={16} textAlign={"center"}>
              End here
            </Typography>
          </ListItemButton>
          <ListItemButton divider sx={{ padding: 1.5 }} onClick={() => onMove(feature)}>
            <Typography width={"100%"} fontSize={16} textAlign={"center"}>
              Move
            </Typography>
          </ListItemButton>
        </List>
      </Paper>

      <Stack gap={1.5} direction={"row"}>
        <Button
          fullWidth
          onClick={closeActionSheet}
          variant="contained"
          sx={{
            textTransform: "capitalize",
            fontSize: 16,
            color: "black",
            padding: 1.5,
            fontWeight: "bold",
            bgcolor: "white",
            borderRadius: 2,
            width: "100%",
            height: "100%",
            overflow: "hidden",
            "&:hover": {
              backgroundColor: "white",
            },
          }}>
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={() => {
            setIsDeleting(true)
            onDelete(feature)
          }}
          sx={{
            borderRadius: 2,
            overflow: "hidden",
          }}>
          <Stack>{isDeleting ? <CircularProgress sx={{ color: "white" }} /> : <HiOutlineTrash size={24} />}</Stack>
        </Button>
      </Stack>
    </Stack>
  )
}

export default FeatureActionSheet
