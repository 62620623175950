import { useState, useEffect } from "react"
import type { Observable } from "@nozbe/watermelondb/utils/rx"

function useObservable<TType>(
  observable: Observable<TType> | undefined,
  initial?: TType,
  deps: any[] = [],
): TType | undefined {
  const [state, setState] = useState(initial)

  useEffect(() => {
    const subscription = observable?.subscribe(setState)
    return () => subscription?.unsubscribe()
  }, deps)

  return state
}

export default useObservable
