import { Stack } from "@mui/material"
import React from "react"
import { Link, type LinkProps } from "react-router-dom"

interface IProps extends LinkProps {
  name: string
  image: string
}

function Brand({ name, image, ...props }: IProps) {
  return (
    <Link style={{ textDecoration: "none" }} {...props}>
      <Stack gap={1} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
        <img draggable={false} width={"80%"} src={image} alt={name} />
      </Stack>
    </Link>
  )
}

export default Brand
