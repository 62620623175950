import React, { useContext } from "react"
import { ThemeProvider } from "@emotion/react"
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Tooltip,
  createTheme,
} from "@mui/material"

import Brand from "../../Atoms/Brand/Brand.component"
import { routes } from "../../../constants/routes.constant"
import LogoWhite from "../../../assets/images/LogoWhite.png"
import LogoWhiteMinimized from "../../../assets/images/mapsource-white-logo.png"
import { NavLink, useLocation, useMatch } from "react-router-dom"

import SettingsContext from "../../../contexts/settings.context"
import { icons } from "../../../interfaces/icons.interface"
import { useLocalStorage } from "usehooks-ts"
import LayoutContext from "../../../contexts/layout.context"
import { LuArrowLeftFromLine, LuArrowRightToLine } from "react-icons/lu"
import type { IUser } from "@utilisourcepackagelibdev/utilisourcepackagelib"
import { usePermissions } from "@utilisourcepackagelibdev/utilisourcepackagelib"
import { WORKSPACE, design } from "../../../constants"

function Sidebar() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  })

  const [user] = useLocalStorage<IUser | null>("user", null)
  const location = useLocation()
  const match = useMatch(location.pathname)
  const {
    settingsState: { activeProject },
  } = useContext(SettingsContext)
  const { layoutState, toggleNavigation } = useContext(LayoutContext)
  console.log(user)
  const { hasAccess, isAdmin } = usePermissions(user, WORKSPACE)
  const isActive = (path: string): boolean => (match ? match.pathname.includes(path) : false)

  return (
    <ThemeProvider theme={darkTheme}>
      <Stack
        sx={{
          width: layoutState.navigationMode === "full" ? `${design.drawerWidth}px` : `${design.drawerMinimizedWidth}px`,
          flexGrow: 1,
          background: "linear-gradient(0deg, #0c355f 2%, rgba(26,47,68,1) 62%);",
          height: "100%",
          top: 0,
          left: 0,
          position: "fixed",
          transitionProperty: "all",
          transitionDuration: "0.1s",
          transitionTimingFunction: "ease-in-out",
        }}>
        <Box
          p={layoutState.navigationMode === "full" ? 2 : 0}
          marginTop={1}
          maxWidth={layoutState.navigationMode === "full" ? "auto" : `${design.drawerMinimizedWidth}px`}>
          <Brand
            to={"/"}
            name={"MapSource"}
            image={layoutState.navigationMode === "full" ? LogoWhite : LogoWhiteMinimized}
          />
        </Box>

        <List>
          <>
            {layoutState.navigationMode === "full" && (
              <ListSubheader
                sx={{ background: "transparent", color: "white", opacity: 0.5, marginTop: 1 }}
                component="div">
                Project
              </ListSubheader>
            )}
            <Divider />
            {activeProject &&
              activeProject.projectId &&
              routes &&
              routes
                .filter((r) => r.group === "Project")
                .map((route, index) => {
                  const Icon = icons[route.icon]
                  return (
                    <React.Fragment key={index}>
                      <ListItemButton
                        selected={isActive(route.path)}
                        component={NavLink}
                        to={route.path}
                        sx={{ paddingY: 1.5 }}
                        style={{ opacity: isActive(route.path) ? 1 : 0.6 }}>
                        <Tooltip title={route.name}>
                          <ListItemIcon
                            sx={{
                              minWidth: layoutState.navigationMode === "full" ? "initial" : "auto",
                              width: layoutState.navigationMode === "full" ? "initial" : "100%",
                              justifyContent: "center",
                            }}>
                            <Icon size={20} />
                          </ListItemIcon>
                        </Tooltip>
                        {layoutState.navigationMode === "full" && (
                          <ListItemText
                            sx={{ marginLeft: 2 }}
                            primaryTypographyProps={{ fontWeight: 500, color: "white" }}
                            primary={route.name}
                          />
                        )}
                      </ListItemButton>
                    </React.Fragment>
                  )
                })}
          </>
          <>
            {routes.filter((r) => r.group === "Settings" && hasAccess(r.name)).length > 0 &&
              layoutState.navigationMode === "full" && (
                <ListSubheader sx={{ background: "transparent", color: "white", opacity: 0.5 }} component="div">
                  Settings
                </ListSubheader>
              )}

            <Divider />

            {user &&
              user.email &&
              routes &&
              routes
                .filter((r) => r.group === "Settings" && (hasAccess(r.name) || isAdmin()))
                .map((route, index) => {
                  const Icon = icons[route.icon]
                  return (
                    <React.Fragment key={index}>
                      <ListItemButton
                        selected={isActive(route.path)}
                        component={NavLink}
                        sx={{ paddingY: 1.5 }}
                        to={route.path}
                        style={{ opacity: isActive(route.path) ? 1 : 0.6 }}>
                        <Tooltip title={route.name}>
                          <ListItemIcon
                            sx={{
                              minWidth: layoutState.navigationMode === "full" ? "initial" : "auto",
                              width: layoutState.navigationMode === "full" ? "initial" : "100%",
                              justifyContent: "center",
                            }}>
                            <Icon size={20} />
                          </ListItemIcon>
                        </Tooltip>
                        {layoutState.navigationMode === "full" && (
                          <ListItemText
                            sx={{ marginLeft: 2 }}
                            primaryTypographyProps={{ fontWeight: 500, color: "white" }}
                            primary={route.name}
                          />
                        )}
                      </ListItemButton>
                    </React.Fragment>
                  )
                })}
          </>
        </List>
        <Box
          sx={{
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            p: 1,
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            borderTop: 1,
            borderTopColor: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            display: "flex",
            justifyContent: "center",
          }}>
          <IconButton onClick={toggleNavigation}>
            {layoutState.navigationMode === "full" ? (
              <LuArrowLeftFromLine size={20} />
            ) : (
              <LuArrowRightToLine size={20} />
            )}
          </IconButton>
        </Box>
      </Stack>
    </ThemeProvider>
  )
}

export default Sidebar
