export const svgToDataURL = (svgString: string) => {
  // const encoded = encodeURIComponent(svgString)
  //                   .replace(/'/g, '%27')
  //                   .replace(/"/g, '%22');
  const encoded = svgString
    .replace("<svg", ~svgString.indexOf("xmlns") ? "<svg" : '<svg xmlns="http://www.w3.org/2000/svg"')

    //
    //   Encode (may need a few extra replacements)
    //
    .replace(/"/g, "'")
    .replace(/%/g, "%25")
    .replace(/#/g, "%23")
    .replace(/{/g, "%7B")
    .replace(/}/g, "%7D")
    .replace(/</g, "%3C")
    .replace(/>/g, "%3E")

    .replace(/\s+/g, " ")

  return `data:image/svg+xml,${encoded}`
}

export const changeSvgColor = (svgString: string, newColor: string) => {
  // Parse the SVG string
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(svgString, "text/xml")

  // Find the elements to change (e.g., all 'path' elements)
  const paths = xmlDoc.getElementsByTagName("path")
  for (let i = 0; i < paths.length; i++) {
    paths[i].setAttribute("fill", newColor) // Change the fill color
  }

  // Serialize the modified SVG back to string
  const serializer = new XMLSerializer()
  const modifiedSvgString = serializer.serializeToString(xmlDoc)
  return modifiedSvgString
}
