import React, { type FC } from "react"
import type { FormDataTypeProps } from "../../../interfaces/formBuilder.interface"
import { Box, Typography } from "@mui/material"

const HeadingItem: FC<FormDataTypeProps> = ({ ...props }: Omit<FormDataTypeProps, "slotProps">) => {
  return (
    <Box width={"100%"}>
      <Typography textAlign={"left"} fontWeight={"bold"} typography={props.description ? props.description : "h5"}>
        {props.label}
      </Typography>
    </Box>
  )
}

export default HeadingItem
