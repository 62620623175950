import { useState, useCallback } from "react"
import { useDatabase } from "@nozbe/watermelondb/hooks"
import { Q } from "@nozbe/watermelondb"
import type Attachment from "../../model/Attachment"
import { TableName } from "../../model/schema"
import useObservable from "../useObservable"

export function useAttachments(path: string) {
  const database = useDatabase()
  const [error, setError] = useState<any | undefined>()
  const attachmentsQuery = database.collections
    .get<Attachment>(TableName.Attachment)
    .query(Q.where("path", path), Q.where("type", "library"))

  const screenshotsQuery = database.collections
    .get<Attachment>(TableName.Attachment)
    .query(Q.where("path", path), Q.where("type", "camera"))

  const $attachments = useObservable<Attachment[]>(attachmentsQuery.observe(), [], [database, path])
  const $screenshots = useObservable<Attachment[]>(screenshotsQuery.observe(), [], [database, path])

  const addAttachment = useCallback(
    async (newAttachmentData: Attachment) => {
      try {
        await database.write(async () => {
          await database.collections.get<Attachment>(TableName.Attachment).create((attachment) => {
            // Assuming newAttachmentData is an object with attachment fields
            Object.assign(attachment, newAttachmentData)
            Object.keys(attachment).forEach((key) => {
              if (Object(attachment)[key] === undefined) {
                delete Object(attachment)[key]
              }
            })
          })
        })
        return newAttachmentData
      } catch (err) {
        setError(err)
      }
    },
    [database],
  )

  // Function to update an existing attachment
  const updateAttachment = useCallback(
    async (updatedAttachmentData: Attachment) => {
      try {
        await database.write(async () => {
          const attachment = await database.collections.get(TableName.Attachment).find(updatedAttachmentData.id)
          await attachment.update((attachment) => {
            Object.assign(attachment, updatedAttachmentData)
          })
        })
      } catch (err) {
        setError(err)
      }
    },
    [database],
  )

  // Function to delete a attachment
  const deleteAttachment = useCallback(
    async (id: string): Promise<string | undefined> => {
      try {
        await database.write(async () => {
          const attachment = await database.collections.get(TableName.Attachment).find(id)
          await attachment.markAsDeleted()
        })
        return "Deleted Successfully"
      } catch (err) {
        setError(err)
      }
    },
    [database],
  )

  return { $attachments, $screenshots, addAttachment, updateAttachment, deleteAttachment, error }
}
