import type { DialogProps } from "@mui/material/Dialog"
import MuiDialog from "../../Atoms/Dialog/MuiDialog.componet"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import IconButton from "../../Atoms/IconButton/IconButton.component"
import CloseIcon from "@mui/icons-material/Close"
import Button from "../../Atoms/Button/Button.component"

interface IProps extends DialogProps {
  setOpen: any
  title?: string
  content?: any
  hideCloseIcon?: boolean
  hideOkayBtn?: boolean
  hideCancelBtn?: boolean
  onSuccess?: any
  closeBtnText?: string
  successBtnText?: string
  closeBtnColor?: "inherit" | "primary" | "secondary" | "error" | "success" | "warning" | "info",
  successBtnColor?: "inherit" | "primary" | "secondary" | "error" | "success" | "warning" | "info",
}

const Modal = (props: IProps) => {
  const { title, content, hideCloseIcon, hideCancelBtn, hideOkayBtn, setOpen, closeBtnText, successBtnText, closeBtnColor, successBtnColor, ...dialogProps } = props

  const handleClose = () => {
    setOpen(false)
  }

  const handleSuccessClick = () => props.onSuccess && props.onSuccess()

  return (
    <MuiDialog {...dialogProps}>
      {title && <DialogTitle></DialogTitle>}
      <DialogContent>{content ? content : null}</DialogContent>
      <DialogActions>
        {!hideCloseIcon && (
          <IconButton
            id="closeModalIcon"
            color="default"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
            onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )}
        {!hideCancelBtn && (
          <Button id="cancelBtn" onClick={handleClose} color={closeBtnColor}>
            {closeBtnText ? closeBtnText : "Cancel"}
          </Button>
        )}
        {!hideOkayBtn && (
          <Button id="successBtn" onClick={handleSuccessClick} color={successBtnColor}>
            {successBtnText ? successBtnText : "Okay"}
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  )
}

export default Modal
