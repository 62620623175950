import type { ISettings } from "../settings.context"
import merge from "lodash/merge"

export const UPDATE_SETTINGS = "UPDATE_SETTINGS"
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR"
export const UPDATE_ACTIVE_SURVEY = "UPDATE_ACTIVE_SURVEY"
export const UPDATE_ACTIVE_FEATURE = "UPDATE_ACTIVE_FEATURE"
export const DELETE_ACTIVE_FEATURE = "DELETE_ACTIVE_FEATURE"

const localStorageSettings = localStorage.getItem("settings")

export const defaultSettings: ISettings = {
  theme: "light",
  worlds: [],
  activeSurveyStatus: "active",
  activeFeatureStatus: "active",
  templatesFilter: {
    selectedCategory: "",
    selectedWorkTypeId: -1,
  },
  featuresFilter: {
    showCompleted: false,
    selectedSurvey: -1,
    selectedTimeFilter: "all",
    selectedStatus: "in progress",
    selectedForm: -1,
    selectedUser: "all",
  },
  featuresExpanded: {},
  surveysFilter: {
    selectedForm: -1,
    selectedTimeFilter: "all",
    selectedStatus: "in progress",
  },
  surveysDetails: {
    openForm: false,
    openFeaturesIndex: true,
  },
  pointsFilter: {
    selectedDevice: -1,
    selectedSurvey: -1,
    selectedFeature: -1,
    selectedUser: "all",
    selectedTimeFilter: "today",
  },
  sidebarMode: "half",
  cameraOpen: false,
}

// const mergeSettings = (currentSettings: any, newSettings: any) => {
//   return merge({}, currentSettings, newSettings)
// }
const mergeSettings = (currentSettings: any, newSettings: any) => {
  // Create a deep clone of the current settings
  const mergedSettings = merge({}, currentSettings)

  // Iterate over the new settings and apply them
  Object.keys(newSettings).forEach((key) => {
    if (newSettings[key] === undefined) {
      // Explicitly set the property to undefined
      mergedSettings[key] = undefined
    } else {
      // Merge non-undefined properties
      mergedSettings[key] = newSettings[key]
    }
  })

  return mergedSettings
}

// export const initialSettingsState: ISettings = localStorageSettings ? JSON.parse(localStorageSettings) : defaultSettings

export const initialSettingsState: ISettings = localStorageSettings
  ? mergeSettings(defaultSettings, JSON.parse(localStorageSettings))
  : defaultSettings

interface IAction {
  type: string
  payload?: ISettings
}

export const settingsReducer = (state: ISettings = initialSettingsState, action: IAction) => {
  let updatedSettings = initialSettingsState
  switch (action.type) {
    case UPDATE_SETTINGS:
      updatedSettings = mergeSettings(state, {
        ...action.payload,
        sidebar: null /* We have to set the sidebar as null when saving settings into local storage */,
      })
      localStorage.setItem("settings", JSON.stringify(updatedSettings))
      return updatedSettings
    case UPDATE_ACTIVE_SURVEY:
      updatedSettings = {
        ...state,
        activeSurvey: action.payload?.activeSurvey,
      }
      localStorage.setItem("settings", JSON.stringify(updatedSettings))
      return updatedSettings
    case UPDATE_ACTIVE_FEATURE:
      updatedSettings = state
      if (action.payload && action.payload.activeFeature) {
        updatedSettings = {
          ...state,
          activeSurvey: {
            ...action.payload?.activeSurvey,
            status: "started",
          },
          activeFeature: {
            ...action.payload?.activeFeature,
            status: "started",
          },
          pointsFilter: {
            ...state.pointsFilter,
            selectedFeature: action.payload?.activeFeature?.featureId ?? -1,
          },
        }
        localStorage.setItem("settings", JSON.stringify(updatedSettings))
      }
      return updatedSettings
    case DELETE_ACTIVE_FEATURE:
      updatedSettings = {
        ...state,
        activeFeature: undefined,
        pointsFilter: {
          ...state.pointsFilter,
          selectedFeature: -1,
        },
      }
      localStorage.setItem("settings", JSON.stringify(updatedSettings))
      return updatedSettings
    default:
      return state
  }
}
