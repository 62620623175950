import { useState } from "react"
import { useMapEvents } from "react-leaflet"
import { type Layer, Util } from "leaflet"
import { groupBy } from "lodash"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import LayersIcon from "@mui/icons-material/Layers"
import createControlledLayer from "./ControlledLayer"
import { LayersControlProvider } from "./CustomLayerControlsContext"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Typography,
} from "@mui/material"

const POSITION_CLASSES: { [key: string]: string } = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
}

interface IProps {
  children: any
  position: string
}

interface ILayerObj {
  layer: Layer
  group: string
  name: string
  checked: boolean
  id: number
}

const LayerControl = ({ position, children }: IProps) => {
  const [collapsed, setCollapsed] = useState(true)
  const [layers, setLayers] = useState<ILayerObj[]>([])
  const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
  const map = useMapEvents({})

  const onLayerClick = (layerObj: ILayerObj) => {
    // Toggle layer visibility
    const newLayers = layers.map((layer) => {
      if (layer.id === layerObj.id) {
        const checked = !layer.checked
        if (checked && map) map.addLayer(layer.layer)
        else {
          if (map) map.removeLayer(layer.layer)
        }
        return { ...layer, checked }
      }
      return layer
    })
    setLayers(newLayers)
  }

  const onGroupAdd = (layer: Layer, name: string, group: string) => {
    const id = Util.stamp(layer)
    const checked = map && map.hasLayer(layer)
    setLayers((prevLayers) => [...prevLayers, { layer, group, name, checked, id }])
  }

  const groupedLayers = groupBy(layers, "group")

  return (
    <LayersControlProvider
      value={{
        layers,
        addGroup: onGroupAdd,
      }}>
      <div className={positionClass}>
        <div className="leaflet-control leaflet-bar">
          <Paper onMouseEnter={() => setCollapsed(false)} onMouseLeave={() => setCollapsed(true)}>
            {collapsed && (
              <IconButton>
                <LayersIcon />
              </IconButton>
            )}
            {!collapsed &&
              Object.keys(groupedLayers).map((section, index) => (
                <Accordion key={`${section} ${index}`}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{section}</Typography>
                  </AccordionSummary>
                  {groupedLayers[section]?.map((layerObj: ILayerObj, index: any) => (
                    <AccordionDetails key={`accDetails_${index}`}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={layerObj.checked}
                            onChange={() => onLayerClick(layerObj)}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label={layerObj.name}
                      />
                    </AccordionDetails>
                  ))}
                </Accordion>
              ))}
          </Paper>
        </div>
        {children}
      </div>
    </LayersControlProvider>
  )
}

const GroupedLayer = createControlledLayer((layersControl, layer, name, group) => {
  layersControl.addGroup(layer, name, group)
})

export default LayerControl
export { GroupedLayer }
