import React, { useContext } from "react"
import {
  Box,
  Divider,
  Typography,
  Stack,
  useTheme,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import type { IAuditLog } from "../../../interfaces/auditLog.interface"
import { ExpandMore } from "@mui/icons-material"
import dayjs from "dayjs"

import Timeline from "@mui/lab/Timeline"
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem"
import TimelineSeparator from "@mui/lab/TimelineSeparator"
import TimelineConnector from "@mui/lab/TimelineConnector"
import TimelineContent from "@mui/lab/TimelineContent"
import TimelineDot from "@mui/lab/TimelineDot"
import { hexToRGB } from "../../FormBuilder/utilities"
import { VscHistory } from "react-icons/vsc"
import LayoutContext from "../../../contexts/layout.context"
import SheetTopBar from "../../Atoms/SheetTopBar/SheetTopBar.component"

interface IProps {
  logs?: IAuditLog[]
}

const AuditList = ({ logs }: IProps) => {
  const theme = useTheme()

  const { closeSidebar } = useContext(LayoutContext)

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: theme.palette.grey[700],
        width: 26,
        height: 26,
        fontWeight: "bold",
        fontSize: 10,
      },
      children: `${name.split(" ").length > 0 ? name.split(" ")[0][0].toUpperCase() : ""}${
        name.split(" ").length > 1 ? name.split(" ")[1][0].toUpperCase() : ""
      }`,
    }
  }

  const renderColor = (log: IAuditLog): "success" | "error" | "primary" | "info" => {
    switch (log.auditType) {
      case "Create":
        return "success"
      case "Delete":
        return "error"
      case "Update":
        return "primary"
      default:
        return "info"
    }
  }

  const camelCaseToWords = (value: string) => {
    return value
      .match(/^[a-z]+|[A-Z][a-z]*/g)
      ?.map(function (x) {
        return x[0].toUpperCase() + x.substr(1).toLowerCase()
      })
      .join(" ")
  }

  const renderDetails = (log: IAuditLog) => {
    if (log && log.data) {
      if (log.auditType === "Create") {
        const payload = JSON.parse(log.data)
        const values = Object.values(payload)

        const filtered = Object.keys(payload).filter((key, index) => values[index] !== null)

        return (
          <Stack gap={1} paddingBottom={1.5}>
            {filtered.map((key, index) => {
              return (
                <Stack key={index}>
                  <Typography variant="caption" color="text.secondary">
                    {camelCaseToWords(key)}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      bgcolor: hexToRGB(theme.palette[renderColor(log)].light, 0.1),
                      color: theme.palette[renderColor(log)].main,
                    }}>
                    <span>+</span> <span style={{ fontStyle: "italic" }}>{values[index] as String}</span>
                  </Typography>
                </Stack>
              )
            })}
          </Stack>
        )
      }
      if (log.auditType === "Update") {
        const payload: {
          Field: string
          OriginalValue: string
          NewValue: string
        }[] = JSON.parse(log.data)

        return (
          <Stack gap={1} paddingBottom={1.5}>
            {payload.map((key, index) => {
              return (
                <Stack key={index}>
                  <Typography variant="caption" color="text.secondary" sx={{ fontWeight: "bold" }}>
                    {camelCaseToWords(key.Field)}
                  </Typography>
                  {/* {key.Field === "form data" ? } */}
                  <Typography
                    variant="caption"
                    sx={{
                      bgcolor: hexToRGB(theme.palette["error"].light, 0.1),
                      color: theme.palette["error"].main,
                    }}>
                    <span>-</span> <span style={{ fontStyle: "italic" }}>{key.OriginalValue}</span>
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      bgcolor: hexToRGB(theme.palette["success"].light, 0.1),
                      color: theme.palette["success"].main,
                    }}>
                    <span>+</span> <span style={{ fontStyle: "italic" }}>{key.NewValue}</span>
                  </Typography>
                </Stack>
              )
            })}
          </Stack>
        )
      }
    }

    return <Typography>No Data</Typography>
  }

  const renderTimeLimeItem = (log: IAuditLog, index: number) => (
    <TimelineItem key={index}>
      <TimelineSeparator>
        <TimelineDot color={renderColor(log)} variant="outlined" />
        {logs && index + 1 !== logs.length && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent sx={{ transform: "translateY(-20px)" }}>
        <Accordion
          style={{ borderRadius: 12 }}
          disableGutters
          elevation={0}
          sx={{ border: `solid 1px ${theme.palette.divider}` }}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
            <Stack sx={{ lineHeight: 0.7 }}>
              <Typography fontSize={14} fontWeight="bold" sx={{ color: theme.palette[renderColor(log)].main }}>
                {log.auditType}
              </Typography>
              <Typography color="text.secondary" variant="caption">
                {log && log.createdAt && dayjs(log.createdAt * 1000).format("MMMM, DD YYYY, hh:mm a")}
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingTop: 0 }}>
            <Box>
              {renderDetails(log)}
              <Divider />
              <Stack alignItems="center" paddingTop={1.8} gap={1} direction="row">
                {log && log.createdBy && <Avatar {...stringAvatar(log.createdBy)} />}
                {log && log.createdBy && <Typography variant="body2">{log.createdBy}</Typography>}
              </Stack>
            </Box>
          </AccordionDetails>
        </Accordion>
      </TimelineContent>
    </TimelineItem>
  )

  return (
    <>
      <Box width={"100%"} position={"relative"}>
        <SheetTopBar
          onClose={() => closeSidebar()}
          avatar={<VscHistory color="white" size={24} />}
          leading={
            <Typography sx={{ color: "white" }} marginY={0} variant="body1" fontWeight="bold">
              Changes ({logs ? logs.length : 0})
            </Typography>
          }
        />
        <Divider />
        <Box>
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
              marginTop: 4,
            }}>
            {logs &&
              logs.map((log, index) => {
                return renderTimeLimeItem(log, index)
              })}
          </Timeline>
        </Box>
      </Box>
    </>
  )
}

export default AuditList
