import { point } from "@turf/helpers"
import type { Dayjs } from "dayjs"
import dayjs from "dayjs"
import { BASE_API_URL } from "../constants/commonStrings.constant"
import type { IPoint } from "../interfaces"
import { apiService } from "./api.service"
import type { DateRange } from "@mui/x-date-pickers-pro/models"
import type { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts"

class PointService {
  async getPoints(arg: {
    projectId?: number
    deviceId?: number
    surveyId?: number
    featureId?: number
  }): Promise<IPoint[]> {
    const url = BASE_API_URL + `/point`
    const { projectId, deviceId, surveyId, featureId } = arg
    const response = await apiService({
      url,
      method: "GET",
      params: {
        projectId,
        deviceId,
        surveyId,
        featureId,
      },
      withCredentials: false,
    })
    const { data } = response

    if (!data) return []

    return data.map(this.processPoint)
  }

  processPoint(p: IPoint): IPoint {
    const geoJson = JSON.parse(p.geoJson ?? "{}")
    return {
      ...p,
      createdAtDate: dayjs((p.createdAt ?? 0) * 1000).format("MM/DD/YYYY hh:mm:ss A"),
      lon: geoJson?.coordinates[0],
      lat: geoJson?.coordinates[1],
      geoFeature: point(geoJson?.coordinates, {
        featureId: p.featureId,
      }),
    }
  }

  async getPointsByLocationAndTime(coordinates: GeolocationCoordinates, createdAt: number): Promise<IPoint[]> {
    const url = BASE_API_URL + `/point/nearby/${createdAt}/${coordinates.longitude}/${coordinates.latitude}`
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })
    const { data } = response
    return data?.map((point: IPoint) => this.processPoint(point))
  }

  async getPoint(id: string): Promise<IPoint> {
    const url = BASE_API_URL + "/point/" + id
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })
    const { data }: { data: IPoint } = response
    return data
  }

  async getUsers(projectId: number): Promise<string[]> {
    const url = BASE_API_URL + `/point/users`

    const response = await apiService.get(url, {
      withCredentials: false,
      params: {
        projectId,
      },
    })
    const { data } = response
    return data
  }

  async addPoint(
    payload: Omit<IPoint, "geoFeature"> /* We have to omit the required property 'geoFeature' to send to db */,
    source: string = "device",
  ): Promise<IPoint> {
    const url = BASE_API_URL + "/point?source=" + source
    const response = await apiService.post(url, payload, {
      withCredentials: false,
    })
    const { data }: { data: IPoint } = response
    console.log(data)
    return data
  }

  async updatePoint(payload: IPoint): Promise<IPoint> {
    const url = BASE_API_URL + "/point"

    const response = await apiService.put(url, payload, {
      withCredentials: false,
    })
    const { data } = response

    return data
  }

  async deletePoint(id: number): Promise<string> {
    const url = BASE_API_URL + `/point/${id}`

    const response = await apiService.delete(url, {
      withCredentials: false,
    })
    const { statusText } = response
    return statusText
  }

  async search(arg: {
    projectId?: number
    time?: string
    deviceId?: number
    surveyId?: number
    featureId?: number
    user?: string
  }): Promise<IPoint[]> {
    const url = BASE_API_URL + `/point/search`
    const { projectId, deviceId, surveyId, featureId, time, user } = arg

    const response = await apiService.get(url, {
      withCredentials: false,
      params: {
        projectId,
        time,
        deviceId,
        surveyId,
        user,
        featureId,
        offset: dayjs().utcOffset(),
      },
    })
    const { data } = response
    return data && data.map((point: IPoint) => this.processPoint(point))
  }

  newPointTimeFilters: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: "Today",
      getValue: () => {
        const today = dayjs()
        return [today.startOf("day"), today.endOf("day")]
      },
    },
    {
      label: "This Week",
      getValue: () => {
        const today = dayjs()
        return [today.startOf("week"), today.endOf("week")]
      },
    },
    {
      label: "Last Week",
      getValue: () => {
        const today = dayjs()
        const prevWeek = today.subtract(7, "day")
        return [prevWeek.startOf("week"), prevWeek.endOf("week")]
      },
    },
    {
      label: "Last 7 Days",
      getValue: () => {
        const today = dayjs()
        return [today.subtract(7, "day"), today]
      },
    },
    {
      label: "Current Month",
      getValue: () => {
        const today = dayjs()
        return [today.startOf("month"), today.endOf("month")]
      },
    },
    {
      label: "All",
      getValue: () => {
        const today = dayjs()
        const start = today.subtract(30, "years")
        const end = today.add(30, "years")
        return [start, end]
      },
    },
    { label: "Reset", getValue: () => [null, null] },
  ]

  pointTimeFilters = [
    {
      id: "today",
      name: "Today",
    },
    {
      id: "yesterday",
      name: "Yesterday",
    },
    {
      id: "last-week",
      name: "Last Week",
    },
  ]
}

export const pointService = new PointService()
