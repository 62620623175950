import {
  Box,
  CircularProgress,
  Container,
  List,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
  ListItemButton,
  Stack,
  Chip,
} from "@mui/material"
import React, { useState, useCallback, useEffect, useContext } from "react"

import type { IWorld } from "../../interfaces/world.interface"
import { worldService } from "../../services"
import SettingsContext from "../../contexts/settings.context"
import { HiGlobeAmericas } from "react-icons/hi2"
import { UPDATE_SETTINGS } from "../../contexts/reducers/settings.reducer"
import { hexToRGB } from "../../components/FormBuilder/utilities"
import LayoutContext from "../../contexts/layout.context"
import { SheetTopBar } from "../../components"

function SelectWordList() {
  const theme = useTheme()
  const [worlds, setWorlds] = useState<IWorld[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { settingsState, updateSettings } = useContext(SettingsContext)
  const { closeSidebar } = useContext(LayoutContext)

  const fetch = useCallback(async () => {
    setIsLoading(true)
    try {
      const res = await worldService.getWorlds()
      if (res) {
        setWorlds(res)
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    fetch()
  }, [])

  const handleOnSelect = (world: IWorld) => {
    updateSettings(UPDATE_SETTINGS, {
      ...settingsState,
      activeWorld: world,
      activeSurvey: undefined,
      activeProject: undefined,
      activeFeature: undefined,
      sidebar: null,
    })
  }

  const renderLoadingContainer = () => (
    <Box width={"100%"} display={"flex"} justifyContent={"center"} py={12} alignItems={"center"}>
      <CircularProgress />
    </Box>
  )

  return (
    <>
      <SheetTopBar
        onClose={() => closeSidebar()}
        avatar={<HiGlobeAmericas color="white" size={36} opacity={0.7} />}
        leading={
          <Stack gap={0}>
            <Typography color={"white"} fontWeight={900}>
              Worlds
            </Typography>
          </Stack>
        }
      />
      <Container maxWidth={false} sx={{ pt: 4, marginBottom: 7 }}>
        {isLoading ? (
          renderLoadingContainer()
        ) : (
          <Box
            sx={{
              background: theme.palette.background.paper,
              borderRadius: 3,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: theme.palette.grey[300],
              overflow: "hidden",
            }}>
            <List sx={{ paddingY: 0 }} dense>
              {worlds &&
                worlds.map((world, index) => {
                  return (
                    <ListItemButton dense sx={{ cursor: "pointer" }} key={index} onClick={() => handleOnSelect(world)}>
                      <ListItemAvatar sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                        <HiGlobeAmericas color={theme.palette.primary.main} size={32} />
                      </ListItemAvatar>

                      <ListItemText
                        primaryTypographyProps={{ fontWeight: "bold" }}
                        secondary={"World"}
                        secondaryTypographyProps={{ fontSize: 12 }}
                        primary={world.worldName}
                      />
                      {settingsState.activeWorld && settingsState.activeWorld.worldId === world.worldId && (
                        <Chip
                          size="small"
                          sx={{
                            backgroundColor: hexToRGB(theme.palette.success.main, 0.1),
                            color: theme.palette.success.main,
                          }}
                          label="Active"
                        />
                      )}
                    </ListItemButton>
                  )
                })}
            </List>
          </Box>
        )}
      </Container>
    </>
  )
}

export default SelectWordList
