import { useTheme, InputAdornment, TextField } from "@mui/material"
import React, { useRef } from "react"
import type { FormDataTypeProps } from "../../../interfaces"
import DynamicIcon from "../lib/DynamicIcon"

const TextFieldInput: React.FC<FormDataTypeProps> = ({ onDataChange, ...props }: FormDataTypeProps) => {
  const theme = useTheme()

  const [value, setValue] = React.useState(props.value ?? null)
  const ref = useRef<HTMLDivElement>(null)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    onDataChange(props.id, e.target.value)
  }

  return (
    <TextField
      ref={ref}
      value={value ?? ""}
      disabled={props.disabled}
      sx={{ "& > .MuiInputBase-root": { backgroundColor: theme.palette.background.paper } }}
      variant="outlined"
      fullWidth
      label={props.label}
      name={props.name}
      size="small"
      placeholder={props.placeholder}
      required={props.required}
      onChange={onChange}
      helperText={props.hintText}
      InputProps={{
        readOnly: props.readOnly,
        required: props.required,
        endAdornment: (
          <InputAdornment position="end">
            <DynamicIcon name={props.type} />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default TextFieldInput
