import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  type SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import type { FormDataTypeProps } from "../../../interfaces/formBuilder.interface"
import { useLocalStorage } from "usehooks-ts"
import { formService } from "../../../services"
import type { ISettings } from "../../../contexts/settings.context"
import { grey } from "@mui/material/colors"

const SingleDropdown: React.FC<FormDataTypeProps & { onSelection?: (value: string) => void }> = ({
  options,
  dataUrl,
  onDataChange,
  onSelection,
  ...props
}: FormDataTypeProps & { onSelection?: (value: string) => void }) => {
  const theme = useTheme()
  const [loading, setLoading] = useState(false)

  // TODO: Why are we using local storage for settings here, instead of the settings context?
  const [settings] = useLocalStorage<ISettings | null>("settings", null)
  const [value, setValue] = useState(props.value ?? "")

  const [_options, setOptions] = useState(options)

  const loadOptions = async () => {
    if (dataUrl) {
      console.log("fetching options...")
      setLoading(true)
      try {
        if (settings && settings.activeProject && settings.activeProject.projectId) {
          const response = await formService.getOptions(dataUrl, settings.activeProject.projectId)
          if (response) setOptions(response)
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    loadOptions()
  }, [dataUrl])

  useEffect(() => (options !== undefined ? setValue(props.value) : undefined), [])
  useEffect(() => {
    const handleReload = (event: CustomEvent) => {
      if (event.detail.inputId === props.id) {
        console.log("rules reloadData triggered in " + props.label)
        loadOptions()
      }
    }
    window.addEventListener("reloadData", handleReload as EventListener)

    return () => {
      window.removeEventListener("reloadData", handleReload as EventListener)
    }
  }, [props.id])

  const onChange = (e: SelectChangeEvent) => {
    console.log("TextFieldInput -> e.target.value", e.target.value)
    setValue(e.target.value)
    onDataChange(props.id, e.target.value)
  }

  return (
    <FormControl variant="outlined" size="small" fullWidth required={props.required} disabled={props.disabled}>
      <InputLabel id="demo-multiple-checkbox-label">{props.label}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id={`multiple-checkbox-${props.id}`}
        readOnly={_options === undefined}
        label={props.label}
        multiple={false}
        startAdornment={
          loading && (
            <Box width={12} height={12}>
              <CircularProgress size={18} />
            </Box>
          )
        }
        native={false}
        value={value ?? ""}
        onChange={(e) => {
          onChange(e)
          if (onSelection) onSelection(e.target.value as string)
        }}
        input={
          <OutlinedInput
            sx={{
              bgcolor: theme.palette.background.paper,
            }}
            fullWidth
            autoComplete="off"
            label={props.label}
          />
        }
        renderValue={(selected) => {
          if (_options === undefined) return selected
          const selectedOption = Object.values(_options).find(
            (option) => option.pkey === selected || option.value === selected,
          )
          return selectedOption ? selectedOption.value : selected
        }}>
        {_options &&
          _options !== undefined &&
          Object.keys(_options).map((key) => (
            <MenuItem key={key} value={_options[key].pkey ? _options[key].pkey : _options[key].value}>
              {_options[key].value}
              {_options[key].description ? (
                <Typography sx={{ ml: 1, color: grey[500], fontSize: "small" }}>{_options[key].description}</Typography>
              ) : null}
            </MenuItem>
          ))}
      </Select>
      {props.hintText && <FormHelperText>{props.hintText}</FormHelperText>}
    </FormControl>
  )
}

export default SingleDropdown
