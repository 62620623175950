import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter as Router } from "react-router-dom"
import "./index.css"
import "material-icons/iconfont/material-icons.css"
import App from "./App"
// import reportWebVitals from "./reportWebVitals"
import GlobalStateProvider from "./contexts/globalStateProvider"
import type { EventMessage, AuthenticationResult } from "@azure/msal-browser"
import { PublicClientApplication, EventType } from "@azure/msal-browser"
import ThemeProvider from "./providers/ThemeProvider"
import { msalConfig } from "./constants/authConfig.constant"
import * as Sentry from "@sentry/react"

Sentry.init({
  dsn: "https://3f3af4fb51201b3d9bb8ddf295dd8bb1@o4507788413894656.ingest.us.sentry.io/4507788415729664",
  integrations: [Sentry.browserTracingIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/mapsourceapi-dev\.azurewebsites\.net\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

export const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      msalInstance.setActiveAccount(account)
    }
  })

  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
  root.render(
    <Sentry.ErrorBoundary>
      <GlobalStateProvider>
        <ThemeProvider>
          <Router>
            <App pca={msalInstance} />
          </Router>
        </ThemeProvider>
      </GlobalStateProvider>
    </Sentry.ErrorBoundary>,
  )
})

// Register service worker
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/sw.js")
      .then((registration) => {
        console.log("Service Worker registered with scope:", registration.scope)
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error)
      })
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
