/* eslint-disable jsx-a11y/no-autofocus */
import React, { useCallback, useContext, useEffect, useState } from "react"
import type { DialogProps, OutlinedInputProps } from "@mui/material"
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Button,
  useTheme,
  Stack,
  Typography,
  DialogActions,
} from "@mui/material"
import Select from "@mui/material/Select"

import type { IFormTemplate } from "../../../interfaces/formResponse.interface"
import type { IFeature, IFeatureStyled } from "../../../interfaces/feature.interface"

import { formService } from "../../../services/form.service"
import { surveyService } from "../../../services"
import type { ISurvey } from "../../../interfaces"
import SettingsContext from "../../../contexts/settings.context"

interface IFeatureDialog extends DialogProps {
  handleClose: () => void
  onDelete: (feature: IFeature) => void
  handleSave: (response: IFeature, form?: IFormTemplate) => void
  feature: IFeature | IFeatureStyled
  mode: "Edit" | "Create" | "Confirm"
}

function FeatureDialog({ handleClose, handleSave, onDelete, mode, feature, ...props }: IFeatureDialog) {
  const [_feature, setFeature] = useState<IFeature>(feature)
  const [selectedSurvey, setSelectedSurvey] = useState<ISurvey>()
  const [selectedForm, setSelectedForm] = useState<IFormTemplate>()
  const [formTemplates, setFormTemplates] = useState<IFormTemplate[]>([])
  const [surveys, setSurveys] = useState<ISurvey[]>([])
  const theme = useTheme()
  const { settingsState } = useContext(SettingsContext)
  const { activeProject } = settingsState

  const fetchForms = useCallback(async () => {
    const forms = await formService.getForms()
    if (forms) {
      setFormTemplates(forms)
      if (feature.formId) {
        const _form = forms.find((form) => form.formId === feature.formId)

        if (_form) {
          setSelectedForm(_form)
        }
      }
    }
  }, [feature.formId])

  useEffect(() => {
    fetchForms()
  }, [fetchForms])

  const fetchSurveys = useCallback(async () => {
    const surveys = await surveyService.search("all", activeProject?.projectId ?? 0, "", -1, "in progress")
    if (surveys) {
      setSurveys(surveys)
      if (feature.surveyId) {
        const _survey = surveys.find((survey) => survey.surveyId === feature.surveyId)

        if (_survey) {
          setSelectedSurvey(_survey)
        }
      }
    }
  }, [feature.formId])

  useEffect(() => {
    fetchSurveys()
  }, [fetchSurveys])

  const FormSelectInput = ({ isLoading, ...props }: { isLoading: boolean } & OutlinedInputProps) => {
    return (
      <OutlinedInput
        startAdornment={
          isLoading ? (
            <Box display={"flex"}>
              <CircularProgress size={16} />
            </Box>
          ) : null
        }
        {...props}
      />
    )
  }

  const renderFormSelectInput = () => (
    <FormControl fullWidth size="small" disabled={mode === "Edit"}>
      <InputLabel id="form-status-label">Feature Type</InputLabel>
      <Select
        labelId="form-status-label"
        id="form-status-select"
        fullWidth
        input={<FormSelectInput isLoading={formTemplates.length === 0} label={"Feature Type"} />}
        value={selectedForm?.formId ?? ""}
        label="Feature Type"
        renderValue={(formId) => formTemplates.find((f) => f.formId === formId)?.formTitle ?? ""}
        onChange={(event) => {
          const filteredTemplate = formTemplates.find((template) => template.formId === event.target.value)

          setSelectedForm(filteredTemplate)
        }}>
        {formTemplates &&
          formTemplates
            .filter((f) => f.category === "Feature")
            .sort((a, b) => a.formTitle.localeCompare(b.formTitle))
            .map((template, index) => {
              return (
                <MenuItem key={index} value={template.formId}>
                  <Stack>
                    <Typography fontSize={14}>{template.formTitle}</Typography>
                    <Typography fontSize={10} color={theme.palette.grey[700]}>
                      {template.workTypeName} | <i>{template.featureTypes}</i>
                    </Typography>
                  </Stack>
                </MenuItem>
              )
            })}
      </Select>
    </FormControl>
  )

  const renderSurveySelectInput = () => (
    <FormControl fullWidth size="small" disabled={mode === "Edit"}>
      <InputLabel id="form-status-label">Job</InputLabel>
      <Select
        labelId="form-status-label"
        id="form-status-select"
        fullWidth
        input={<FormSelectInput isLoading={surveys.length === 0} label={"Survey"} />}
        value={selectedSurvey?.surveyId ?? ""}
        label="Survey"
        renderValue={(surveyId) => surveys.find((f) => f.surveyId === surveyId)?.surveyName ?? ""}
        onChange={(event) => {
          const filteredSurvey = surveys.find((s) => s.surveyId === event.target.value)
          setSelectedSurvey(filteredSurvey)
          setFeature({
            ..._feature,
            surveyId: event.target.value as number,
            status: "started",
          })
        }}>
        {surveys &&
          surveys
            .sort((a, b) => a.surveyName.localeCompare(b.surveyName ?? ""))
            .map((survey, index) => {
              return (
                <MenuItem key={index} value={survey.surveyId}>
                  <Stack>
                    <Typography fontSize={14}>{survey.surveyName ?? ""}</Typography>
                    <Typography fontSize={10} color={theme.palette.grey[700]}>
                      {survey.formTitle} | <i>{survey.workTypeName}</i>
                    </Typography>
                  </Stack>
                </MenuItem>
              )
            })}
      </Select>
    </FormControl>
  )

  const handleOnClose = () => {
    setFeature({
      ..._feature,
      featureName: "",
      //forms: []
    })
    handleClose()
  }

  // const handleAddForm = () => {
  //   if (formTemplates.length >= 0) {
  //     setFeature((feature) => {
  //       // feature.forms.push(formTemplates[0])
  //       return {
  //         ...feature,
  //       };
  //     });
  //   }
  // };

  return (
    <Dialog
      {...props}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.background.default,
        },
      }}
      disableEscapeKeyDown
      fullWidth>
      <DialogTitle>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography typography={"h6"}>{mode} Feature</Typography>
          <Stack gap={1} direction={"row"}></Stack>
        </Box>
      </DialogTitle>
      <Divider />
      {/* {renderMap()} */}

      <DialogContent
        sx={{
          backgroundColor: theme.palette.background.paper,
        }}>
        <DialogContentText>Enter the feature details below.</DialogContentText>

        <Box display={"flex"} flexDirection={"column"} marginTop={1}>
          {renderFormSelectInput()}
        </Box>
        <Box display={"flex"} flexDirection={"column"} marginTop={1}>
          {renderSurveySelectInput()}
        </Box>

        <TextField
          autoFocus
          required
          margin="dense"
          id="feature-name"
          label="Name"
          type="text"
          disabled={selectedForm === undefined}
          autoComplete="false"
          fullWidth
          value={_feature.featureName}
          size="small"
          onChange={(event) =>
            setFeature({
              ..._feature,
              featureName: event.target.value,
            })
          }
          variant="outlined"
        />

        <TextField
          margin="dense"
          disabled={selectedForm === undefined}
          id="feature-description"
          label="Description"
          type="text"
          autoComplete="false"
          fullWidth
          value={_feature.featureDescription ?? ""}
          size="small"
          onChange={(event) =>
            setFeature({
              ..._feature,
              featureDescription: event.target.value,
            })
          }
          variant="outlined"
        />

        {mode === "Edit" && (
          <Button onClick={() => onDelete(feature)} sx={{ marginY: 2 }} color="error" fullWidth variant="outlined">
            DELETE SURVEY
          </Button>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          id="cancel"
          size="small"
          variant="outlined"
          color="error"
          sx={{ fontWeight: "medium" }}
          onClick={() => handleOnClose()}>
          Cancel
        </Button>
        <Button
          size="small"
          disableElevation
          disabled={
            _feature.featureName === null || _feature.featureName === undefined || _feature.featureName.length < 3
          }
          variant="contained"
          id="save-close"
          onClick={() => {
            handleSave(_feature, selectedForm)
            handleOnClose()
          }}>
          {mode}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FeatureDialog
