import React, { forwardRef } from "react"
import { formLibraryComponents } from "../utilities"
import type { FormDataTypeProps } from "../../../interfaces/formBuilder.interface"
import { Box } from "@mui/material"

const DynamicComponent = forwardRef<HTMLDivElement, { name: string } & FormDataTypeProps>(
  ({ name, type, ...props }, ref) => {
    const Component = formLibraryComponents[type]
    return (
      <Box width={"100%"}>
        <Component ref={ref} name={name} type={type} {...props} />
      </Box>
    )
  },
)

export default DynamicComponent
