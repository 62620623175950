import type { ReactNode } from "react"
import { createContext, useEffect, useState } from "react"

import { sync } from "../model/sync"
import { database } from "../model/database"
import debounce from "lodash/debounce"
import { Box } from "@mui/material"
import { TableName } from "../model/schema"
import { SignalRContext } from "../App"

async function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const SyncContext = createContext<{
  isSyncing: boolean
  queueSync: ({ reset, broadcast }?: { reset?: boolean; broadcast?: boolean }) => void
  reset: () => void
}>({
  isSyncing: false,
  queueSync: () => {},
  reset: () => {},
})

const syncDelay = 0

export function SyncProvider({ children }: { children: ReactNode }) {
  const [isResetting, setIsResetting] = useState(false)

  // Change this default to true to force a reset on startup
  const [isReadyToReset, setIsReadyToReset] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [isSyncQueued, setIsSyncQueued] = useState(false)

  SignalRContext.useSignalREffect(
    "DeviceUpdate", // Your Event Key
    // @ts-ignore
    (payload: any) => {
      console.log(payload)
      queueSync()
    },
    [],
  )

  // On initial load, queue sync and list for app state changes
  useEffect(() => {
    console.log("Initial sync")
    queueSync()

    document.addEventListener("visibilitychange", () => {
      queueSync()
    })

    return () => {
      document.addEventListener("visibilitychange", () => {
        queueSync()
      })
    }
  }, [])

  // Listen for db events as along as we're not resetting
  useEffect(() => {
    if (!isResetting) {
      const subscription = database.withChangesForTables([TableName.Attachment]).subscribe({
        next: (changes) => {
          const changedRecords = changes?.filter((c) => c.record.syncStatus !== "synced")

          if (changes?.length ?? changedRecords?.length) {
            console.log("♻️ Database changes", changes?.length, changedRecords?.length)
          }

          if (changedRecords?.length) {
            const debouncedSync = debounce(() => queueSync(), syncDelay)
            debouncedSync()
          }
        },
        error: (error) => console.error("♻️ Database changes error", error),
      })

      console.log("♻️ Subscribed to database changes", {
        closed: subscription.closed,
      })

      return () => {
        subscription.unsubscribe()
        console.log("Unsubscribed from database changes")
      }
    } else {
      setIsReadyToReset(true)
    }
  }, [database, isResetting])

  function queueSync() {
    setIsSyncQueued(true)
    console.log("queueSync", { isSyncing, isSyncQueued })
  }

  /* useEffect(() => {
    if (!isSyncing && isSyncQueued) {
      sync()
        .then(() => {
          console.log("♻️ Sync succeeded");
          sendBroadcast({
            type: "broadcast",
            event: "sync",
          });
        })
        .catch((reason) => {
          console.log("♻️ Sync failed", reason);
        })
        .finally(() => {
          setIsSyncing(false);
        });
    }
  }, [isSyncing, isSyncQueued]); */

  // If not syncing but sync is queued, execute sync
  useEffect(() => {
    if (!isSyncing && isSyncQueued) {
      executeSync()
    }
  }, [isSyncing, isSyncQueued])

  // To reset, set isResetting to true, which will unmount all screens and set isReadyToReset to true via onLayout
  function reset() {
    setIsResetting(true)
  }

  // If ready to reset and not syncing, execute sync
  useEffect(() => {
    if (isReadyToReset && !isSyncing) {
      console.log("Ready to reset")
      executeSync(true)
    }
  }, [isReadyToReset, isSyncing])

  async function executeSync(reset?: boolean) {
    if (reset) setIsReadyToReset(false)
    setIsSyncQueued(false)
    setIsSyncing(true)
    // If this is a reset, delay sync call by one second to give the app time to umount all screens
    if (reset) {
      await delay(1000)
    }
    sync({ reset })
      .then(() => {
        console.log("♻️ Sync succeeded", { reset })
        // sendBroadcast({
        //   type: "broadcast",
        //   event: "sync",
        // });
      })
      .catch((reason) => {
        console.log("♻️ Sync failed", { reset, reason })
      })
      .finally(() => {
        setIsSyncing(false)
        if (reset) setIsResetting(false)
      })
  }

  return isResetting ? (
    <Box>Syncing...</Box>
  ) : (
    <SyncContext.Provider
      value={{
        isSyncing,
        queueSync,
        reset,
      }}>
      {children}
    </SyncContext.Provider>
  )
}
