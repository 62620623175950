export const exportFile = (blob: Blob, filename: string): void => {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getFileNameFromContentDisposition = (contentDisposition: string): string => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(contentDisposition)
  if (matches !== null && matches[1]) {
    return matches[1].replace(/['"]/g, "")
  }
  return "downloaded_file"
}

export const hasNode = (nodes: any, key: string, value: any) => {
  for (const node of nodes) {
    if (node[key] === value) {
      return true
    }
    if (node.children && hasNode(node.children, key, value)) {
      return true
    }
  }
  return false
}
