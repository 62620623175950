import React, { useEffect, useState } from "react"
import { Box, Stack } from "@mui/material"
import DynamicComponent from "./lib/DynamicComponent"
import type { IFormAttachment, FormDataObject, ISurveyAttachment } from "../../interfaces"
import ConditionalItem from "./lib/ConditionalItem"
import RepeatableItem from "./lib/RepeatableItem"
import { v4 } from "uuid"
import { insertFormObjectAt } from "./utilities"

interface Props {
  readOnly: boolean
  formData?: string
  attachments?: ISurveyAttachment[]
  onSave: (data: string) => void
  onFileUpload?: (payload: IFormAttachment) => Promise<IFormAttachment | undefined>
  onFileDelete?: (image: IFormAttachment) => Promise<string>
}

function FormRenderer({ onSave, readOnly, onFileUpload, onFileDelete, formData }: Props) {
  const templateItems: FormDataObject[] = JSON.parse(formData ?? "[]")
  const [formDataItems, setFormDataItems] = useState<FormDataObject[]>(templateItems)

  useEffect(() => {
    if (formData) {
      var json: FormDataObject[] = JSON.parse(formData)

      if (json) {
        setFormDataItems(json)
      }
    }
  }, [formData])

  const handleOnChange = (inputId: string, value: any, sectionId?: string) => {
    console.log("FORM DATA info", inputId, value)
    console.log("TESTING FORM DATA FIRST", formDataItems)
    // deep clone the formDataItems
    const formDataItemsClone = JSON.parse(JSON.stringify(formDataItems))

    const sectionIndex = formDataItems.findIndex((item) => item.id === sectionId)
    if (sectionIndex >= 0) {
      const inputIndex = formDataItems[sectionIndex].children.findIndex((child) => child.id === inputId)
      if (inputIndex >= 0) {
        formDataItemsClone[sectionIndex].children[inputIndex].value = value
      }
    }

    setFormDataItems(formDataItemsClone)

    console.log("TESTING CLONE FORM DATA", formDataItemsClone)
    console.log("TESTING FORM DATA", formDataItems)

    onSave(JSON.stringify(formDataItemsClone))
  }

  const handleOnDeleteAttachment = async () => {}

  const handleOnRepeat = (item: FormDataObject, sectionIndex?: number) => {
    if (item.type === "section") {
      const newItem: FormDataObject = {
        ...item,
        id: v4(),
        duplicated: true,
        children: [
          ...item.children.map((i) => {
            return { ...i, id: v4(), value: null }
          }),
        ],
      }

      const index = formDataItems.findIndex((section) => section.id === item.id)
      if (index >= 0) {
        const newItems = insertFormObjectAt(index + 1, { ...newItem, repeatable: true }, formDataItems)
        newItems[index].repeatable = true
        setFormDataItems([...newItems])
        onSave(JSON.stringify(newItems))
        return
      }
    }

    if (sectionIndex !== undefined) {
      const inputIndex = formDataItems[sectionIndex].children.findIndex((i) => i.id === item.id)
      if (inputIndex >= 0) {
        formDataItems[sectionIndex].children[inputIndex].repeatable = true
        formDataItems[sectionIndex].children = [
          ...insertFormObjectAt(
            inputIndex + 1,
            { ...item, id: v4(), value: null, duplicated: true, repeatable: true },
            formDataItems[sectionIndex].children,
          ),
        ]
        setFormDataItems([...formDataItems])
        onSave(JSON.stringify(formDataItems))
        return
      }
    }
  }

  const handleOnDelete = (item: FormDataObject, sectionIndex?: number) => {
    if (item.type === "section") {
      const index = formDataItems.findIndex((section) => section.id === item.id)
      if (index >= 0) {
        const newItems = formDataItems.filter((i) => i.id !== item.id)
        setFormDataItems([...newItems])
        onSave(JSON.stringify(newItems))
        return
      }
    }

    if (sectionIndex !== undefined) {
      formDataItems[sectionIndex].children = [...formDataItems[sectionIndex].children.filter((i) => i.id !== item.id)]
      setFormDataItems([...formDataItems])
      onSave(JSON.stringify(formDataItems))
      return
    }
  }

  return (
    <Box>
      <Stack gap={1.5}>
        {formDataItems &&
          formDataItems.map((item, index) => {
            return (
              <RepeatableItem
                type="section"
                key={item.id}
                item={item}
                onDelete={(item) => handleOnDelete(item)}
                onRepeat={(item) => handleOnRepeat(item)}>
                <ConditionalItem item={item} items={formDataItems}>
                  <DynamicComponent
                    {...item}
                    onRepeat={(item) => handleOnRepeat(item, index)}
                    onDelete={(item) => handleOnDelete(item, index)}
                    readOnly={readOnly}
                    onFileUpload={onFileUpload}
                    onFileDelete={onFileDelete}
                    onAttachmentDelete={handleOnDeleteAttachment}
                    onDataChange={handleOnChange}
                  />
                </ConditionalItem>
              </RepeatableItem>
            )
          })}
      </Stack>
    </Box>
  )
}

export default FormRenderer
