import type { ReactNode, Reducer } from "react"
import React, { useReducer } from "react"
import type { IProject, IWorld, ISurvey, IFeatureStyled, ISurveyFilterStatus, IFeature } from "../interfaces"
import {
  DELETE_ACTIVE_FEATURE,
  TOGGLE_SIDEBAR,
  UPDATE_ACTIVE_FEATURE,
  UPDATE_ACTIVE_SURVEY,
} from "./reducers/settings.reducer"
import { surveyService, userService } from "../services"
import type { IFeatureFilterStatus } from "../interfaces/survey.interface"

export interface ISettingsContext {
  settingsState: ISettings
  updateSettings: (type: string, payload: ISettings) => void
  toggleSidebar: (payload?: ReactNode, mode?: "half" | "full") => void
  setActiveSurvey: (survey: ISurvey) => Promise<void>
  setActiveFeature: (feature: IFeature, survey: ISurvey) => Promise<void>
  removeActiveFeature: () => Promise<void>
}

export interface ISettings {
  theme: "dark" | "light"
  worldId?: number
  sidebar?: ReactNode
  activeWorld?: IWorld
  position?: GeolocationPosition
  projectId?: number
  worlds: IWorld[]
  deviceId?: number
  deviceSerialNumber?: string
  activeProject?: IProject
  activeSurvey?: ISurvey
  activeSurveyStatus: "active" | "paused"
  activeFeature?: IFeatureStyled | IFeature
  activeFeatureStatus: "active" | "paused"
  templatesFilter: {
    selectedCategory: string
    selectedWorkTypeId: number
  }
  featuresFilter: {
    showCompleted: boolean
    selectedSurvey: number
    selectedTimeFilter: string
    selectedForm: number
    selectedStatus: IFeatureFilterStatus
    selectedUser?: string
  }
  featuresExpanded?: any
  surveysFilter: {
    selectedForm: number
    selectedTimeFilter: string
    selectedStatus: ISurveyFilterStatus
  }
  surveysDetails: {
    openForm: boolean
    openFeaturesIndex: boolean
  }
  pointsFilter: {
    selectedDevice: number
    selectedSurvey: number
    selectedFeature: number
    selectedUser: string
    selectedTimeFilter: string
  }
  sidebarMode: "half" | "full"
  cameraOpen?: boolean
}

const SettingsContext = React.createContext<ISettingsContext>({
  settingsState: {
    theme: "light",
    activeSurveyStatus: "active",
    activeFeatureStatus: "active",
    worlds: [],
    templatesFilter: {
      selectedCategory: "",
      selectedWorkTypeId: -1,
    },
    featuresFilter: {
      showCompleted: false,
      selectedSurvey: -1,
      selectedForm: -1,
      selectedStatus: "started",
      selectedTimeFilter: "all",
      selectedUser: "all",
    },
    featuresExpanded: {},
    surveysFilter: {
      selectedForm: -1,
      selectedTimeFilter: "all",
      selectedStatus: "in progress",
    },
    surveysDetails: {
      openForm: false,
      openFeaturesIndex: true,
    },
    pointsFilter: {
      selectedDevice: -1,
      selectedSurvey: -1,
      selectedUser: "all",
      selectedFeature: -1,
      selectedTimeFilter: "today",
    },
    sidebarMode: "half",
    cameraOpen: false,
  },
  updateSettings: () => {},
  setActiveSurvey: async () => {},
  removeActiveFeature: async () => {},
  setActiveFeature: async () => {},
  toggleSidebar: () => {},
})

export const SettingsProvider = ({ children, reducer, initialState }: any) => {
  const [globalSettingsState, settingsDispatch] = useReducer<Reducer<ISettings, any>>(reducer, initialState)

  const updateSettings = (type: string, payload: ISettings) => {
    settingsDispatch({ type, payload })
  }

  const toggleSidebar = (sidebar?: ReactNode, sidebarMode?: "half" | "full") => {
    settingsDispatch({
      type: TOGGLE_SIDEBAR,
      payload: {
        sidebar,
        sidebarMode,
      },
    })
  }

  const setActiveSurvey = async (survey: ISurvey) => {
    const response = await surveyService.updateSurvey({ ...survey, status: "started" })
    settingsDispatch({
      type: UPDATE_ACTIVE_SURVEY,
      payload: { activeSurvey: response },
    })
  }

  const setActiveFeature = async (feature: IFeature, survey: ISurvey) => {
    // const response = await featureService.updateFeature({ ...feature, status: "started" })
    if (feature.featureId) {
      await userService.updateUserFeature(feature.featureId)
    }
    settingsDispatch({
      type: UPDATE_ACTIVE_FEATURE,
      payload: { activeFeature: feature, activeSurvey: survey },
    })
  }

  const removeActiveFeature = async () => {
    await userService.updateUserFeature(-1)
    settingsDispatch({
      type: DELETE_ACTIVE_FEATURE,
    })
  }

  return (
    <SettingsContext.Provider
      value={{
        settingsState: globalSettingsState,
        updateSettings,
        removeActiveFeature,
        setActiveSurvey,
        setActiveFeature,
        toggleSidebar,
      }}>
      {children}
    </SettingsContext.Provider>
  )
}

export const SettingsStateConsumer = SettingsContext.Consumer

export default SettingsContext
