import React, { type FC } from "react"
import type { FormDataObject } from "../../../interfaces/formBuilder.interface"
import { Box } from "@mui/material"

const ParagraphItem: FC<FormDataObject> = (props: FormDataObject) => {
  const markup = { __html: props.value }
  return (
    <Box width={"100%"}>
      <div dangerouslySetInnerHTML={markup}></div>
    </Box>
  )
}

export default ParagraphItem
