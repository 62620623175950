import { useState, useEffect } from "react"

type UseCurrentLocationReturn = {
  currentLocation: {
    latitude: number | null
    longitude: number | null
    accuracy: number | null
  }
  error: string | null
}

const DEFAULT_TIMEOUT = 5 //? 5 Seconds

const useCurrentLocation = (): UseCurrentLocationReturn => {
  const [error, setError] = useState<string | null>(null)
  const [currentLocation, setCurrentLocation] = useState<{
    latitude: number | null
    longitude: number | null
    accuracy: number | null
  }>({
    latitude: null,
    longitude: null,
    accuracy: null,
  })

  useEffect(() => {
    const handleSuccess = (position: GeolocationPosition) => {
      setCurrentLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        accuracy: position.coords.accuracy,
      })
    }

    const handleError = (error: GeolocationPositionError) => {
      setError(error.message)
    }

    const options: PositionOptions = {
      enableHighAccuracy: true,
      timeout: DEFAULT_TIMEOUT * 1000,
      maximumAge: 0,
    }

    let watcher: number | null = null
    if (navigator.geolocation) {
      ;(async () => {
        try {
          const permission = await navigator.permissions.query({ name: "geolocation" })
          if (permission.state !== "denied") {
            watcher = navigator.geolocation.watchPosition(handleSuccess, handleError, options)
          }
        } catch (e) {
          console.log(e)
          // @ts-ignore
          setError(e.message)
        }
      })()
    }

    return () => {
      if (watcher !== null) {
        navigator.geolocation.clearWatch(watcher)
      }
    }
  }, [])

  return { currentLocation, error }
}

export default useCurrentLocation
