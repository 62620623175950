import {
  Box,
  CircularProgress,
  Container,
  List,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
  ListItemButton,
  Stack,
  Chip,
} from "@mui/material"
import React, { useState, useCallback, useEffect, useContext } from "react"

import SettingsContext from "../../contexts/settings.context"
import { HiGlobeAmericas } from "react-icons/hi2"
import { VscTools } from "react-icons/vsc"
import { UPDATE_SETTINGS } from "../../contexts/reducers/settings.reducer"
import { hexToRGB } from "../../components/FormBuilder/utilities"
import type { IProject } from "../../interfaces"
import { projectService } from "../../services"
import { useNavigate } from "react-router-dom"
import LayoutContext from "../../contexts/layout.context"
import { SheetTopBar } from "../../components"

function SelectProjectList() {
  const theme = useTheme()
  const [projects, setProjects] = useState<IProject[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { settingsState, updateSettings } = useContext(SettingsContext)
  const { closeSidebar } = useContext(LayoutContext)
  const navigate = useNavigate()

  const fetch = useCallback(async () => {
    setIsLoading(true)
    try {
      const res = await projectService.getAll()
      if (res) {
        setProjects(res)
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    fetch()
  }, [])

  const handleOnSelect = (project: IProject) => {
    updateSettings(UPDATE_SETTINGS, {
      ...settingsState,
      activeProject: project,
      activeSurvey: undefined,
      activeFeature: undefined,
      sidebar: null,
    })

    navigate("/")
  }

  const renderLoadingContainer = () => (
    <Box width={"100%"} display={"flex"} justifyContent={"center"} py={12} alignItems={"center"}>
      <CircularProgress />
    </Box>
  )

  return (
    <>
      <SheetTopBar
        onClose={() => closeSidebar()}
        avatar={<VscTools color="white" size={36} opacity={0.7} />}
        leading={
          <Stack gap={0}>
            <Typography color={"white"} fontWeight={900}>
              Projects
            </Typography>
          </Stack>
        }
      />

      <Container maxWidth={false} sx={{ pt: 4, marginBottom: 7 }}>
        {isLoading ? (
          renderLoadingContainer()
        ) : (
          <Box
            sx={{
              background: theme.palette.background.paper,
              borderRadius: 3,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: theme.palette.grey[300],
              overflow: "hidden",
            }}>
            <List sx={{ paddingY: 0 }} dense>
              {projects &&
                projects.map((project, index) => {
                  return (
                    <ListItemButton
                      dense
                      sx={{ cursor: "pointer" }}
                      key={index}
                      onClick={() => handleOnSelect(project)}>
                      <ListItemAvatar sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                        <HiGlobeAmericas color={theme.palette.primary.main} size={32} />
                      </ListItemAvatar>

                      <ListItemText
                        primaryTypographyProps={{ fontWeight: "bold" }}
                        secondary={"Project"}
                        secondaryTypographyProps={{ fontSize: 12 }}
                        primary={project.projectName}
                      />
                      {settingsState.activeProject && settingsState.activeProject.projectId === project.projectId && (
                        <Chip
                          size="small"
                          sx={{
                            backgroundColor: hexToRGB(theme.palette.success.main, 0.1),
                            color: theme.palette.success.main,
                          }}
                          label="Active"
                        />
                      )}
                    </ListItemButton>
                  )
                })}
            </List>
          </Box>
        )}
      </Container>
    </>
  )
}

export default SelectProjectList
