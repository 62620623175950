import { InputAdornment, TextField, useTheme } from "@mui/material"
import type { FC } from "react"
import React, { useState } from "react"
import type { NumericFormatProps } from "react-number-format"
import { NumericFormat } from "react-number-format"
import PriceChangeTwoTone from "@mui/icons-material/PriceChangeTwoTone"
import type { FormDataTypeProps } from "../../../interfaces/formBuilder.interface"

interface Props {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, Props>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator="."
      decimalScale={2}
      thousandSeparator
    />
  )
})

const DecimanlFieldInput: FC<FormDataTypeProps> = ({ onDataChange, ...props }: FormDataTypeProps) => {
  const theme = useTheme()

  const [value, setValue] = useState(props.value ?? null)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    onDataChange(props.id, e.target.value)
  }

  return (
    <TextField
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        endAdornment: (
          <InputAdornment position="end">
            <PriceChangeTwoTone />
          </InputAdornment>
        ),
      }}
      label={props.label}
      fullWidth
      size="small"
      value={value ?? ""}
      disabled={props.disabled}
      sx={{ bgcolor: theme.palette.background.paper }}
      variant="outlined"
      type="number"
      name={props.name}
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      onChange={onChange}
      helperText={props.hintText}
    />
  )
}

export default DecimanlFieldInput
