import type { IDevice } from "../interfaces/device.interface"
import { BASE_API_URL, BASE_API_LISTENER_URL } from "../constants/commonStrings.constant"
import { apiService } from "./api.service"

class DeviceService {
  async getDevices(): Promise<IDevice[]> {
    const url = BASE_API_URL + `/device`
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })
    const { data }: { data: IDevice[] } = response
    return data
  }

  async getDeviceById(id: number): Promise<IDevice> {
    const url = BASE_API_URL + `/device/${id}`
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })
    const { data }: { data: IDevice } = response
    return data
  }

  async addDevice(payload: IDevice): Promise<IDevice> {
    const url = BASE_API_URL + `/device`
    const res = await apiService.post(url, payload, { withCredentials: false })
    const { data }: { data: IDevice } = res
    return data
  }

  async getDeviceBySerialNumber(deviceSerialNumber: string): Promise<IDevice> {
    const url = BASE_API_URL + `/device/search-by-serial-number/${deviceSerialNumber}`
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })
    const { data }: { data: IDevice } = response
    return data
  }

  async scan(serialNumber: string, dateCutoff: string = ""): Promise<IDevice[]> {
    const url = BASE_API_LISTENER_URL + `/api/listener/scan`
    const params: {
      serialNumber: string
      dateCutoff?: string
    } = {
      serialNumber,
    }
    if (dateCutoff && dateCutoff !== "") {
      params["dateCutoff"] = dateCutoff
    }
    const response = await apiService({
      url,
      method: "GET",
      params,
      withCredentials: false,
    })
    const { data }: { data: IDevice[] } = response
    return data
  }
}

export const deviceService = new DeviceService()
