import type { Theme } from "@mui/material"
import { Alert, AlertTitle, Box, Collapse, Stack, Typography, useMediaQuery } from "@mui/material"
import React, { useEffect, useState } from "react"
import type { IAlert } from "../../../interfaces"

function MSAlert(props: { alert?: IAlert }) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  const [alert, setAlert] = useState<IAlert | undefined>(props.alert)

  useEffect(() => {
    setAlert(props.alert)
    setTimeout(() => {
      setAlert(undefined)
    }, 50000)
  }, [props.alert])

  return (
    <Collapse in={alert !== undefined}>
      <Box px={3} pt={1.5}>
        <Alert
          severity={alert?.color}
          sx={{ borderRadius: 5 }}
          onClose={() => setAlert(undefined)}
          action={isDesktop && alert && alert.action ? alert.action : undefined}>
          <AlertTitle>
            <Typography fontWeight={"bold"}>{alert?.title}</Typography>
          </AlertTitle>
          {alert && alert.description ? alert.description : ""}
          {!isDesktop && alert && alert.action && (
            <Stack direction={"row"} justifyContent={"flex-end"} mt={1}>
              {alert.action}
            </Stack>
          )}
        </Alert>
      </Box>
    </Collapse>
  )
}

export default MSAlert
