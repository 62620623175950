import { Model } from "@nozbe/watermelondb"
import { field, readonly, date, text } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"

export default class Attachment extends Model {
  static table = TableName.Attachment

  @field("formId") formId?: number
  @field("attachmentId") attachmentId?: number
  @field("orientation") orientation?: number
  @field("longitude") longitude?: number
  @field("latitude") latitude?: number
  @text("path") path?: string
  @text("name") name?: string
  @text("fileName") fileName?: string
  @text("data") data?: string
  @text("url") url?: string
  @text("fieldId") fieldId?: string
  @text("type") type?: string

  @readonly @date("created_at") created_at!: number
  @readonly @date("updated_at") updated_at?: number
}
