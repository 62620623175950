import { synchronize } from "@nozbe/watermelondb/sync"
import { database } from "./database"
import { BASE_API_URL } from "../constants/commonStrings.constant"
import { apiService } from "../services/api.service"

export async function sync({
  reset,
}: {
  reset?: boolean
} = {}) {
  if (reset) {
    await database.write(async () => {
      await database.unsafeResetDatabase()
      console.log("✅ DB reset")
    })
  }
  await synchronize({
    database,
    pullChanges: async ({ lastPulledAt, schemaVersion, migration }) => {
      console.log("🍉 ⬇️ Pulling changes ...", { lastPulledAt })
      const urlParams = `lastSyncAt=${lastPulledAt ?? 0}&schema_version=${schemaVersion ?? 0}&migration=${encodeURIComponent(
        JSON.stringify(migration),
      )}`
      const response = await apiService.get(`${BASE_API_URL}/sync?${urlParams}`)
      if (!(response.status === 200)) {
        throw new Error("🍉".concat(await response.data))
      }

      const { changes, timestamp } = response.data
      // console.log("Pull response",{changes, timestamp})
      console.log(`🍉 Changes pulled at ${new Date(timestamp).toISOString()} UTC`, changes)
      return { changes, timestamp }
    },
    pushChanges: async ({ changes, lastPulledAt }) => {
      console.log({ changes, lastPulledAt })
      // const response = await fetch(`${BASE_API_URL}/sync`, {
      //   method: "POST",
      //   headers: { "content-type": "application/json" },
      //   body: JSON.stringify({ changes }),
      // })
      try {
        const response = await apiService.post(
          `${BASE_API_URL}/sync`,
          { changes },
          {
            headers: { "Content-Type": "application/json" },
          },
        )
        console.log(response)
      } catch (error: any) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          throw new Error(error.response.data || "Unknown Error")
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received:", error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message)
        }
      }
    },
    // migrationsEnabledAtVersion: 1,
    // sendCreatedAsUpdated: true,
  })
}
