import { Box, Button, Typography, useTheme } from "@mui/material"
import React, { type FC, createRef, useEffect, useState, useRef } from "react"
import type { FormDataObject, FormDataTypeProps } from "../../../interfaces"
import SignatureCanvas from "react-signature-canvas"

interface SignaturePadProps extends FormDataObject {
  onDrawUpdate?: (form: FormDataObject) => void
  onDrawEnd?: (form: FormDataObject) => void
  onClear?: () => void
}

const SignaturePad: FC<SignaturePadProps & FormDataTypeProps> = ({
  onDrawUpdate,
  onDrawEnd,
  onClear,
  onDataChange,
  ...props
}: SignaturePadProps & FormDataTypeProps) => {
  const theme = useTheme()
  const containerRef = createRef<HTMLDivElement>()
  const signatureRef = useRef<SignatureCanvas | null>(null)

  const [value, setValue] = React.useState(props.value ?? null)
  // const [element, setElement] = useState<FormDataObject>(props)

  const [width, setWidth] = useState<number | undefined>()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const HEIGHT: number = 200

  // const handleOnUpdate = useCallback(() => {
  //   if (!props.readOnly && signatureRef.current && signatureRef.current.toData().length > 0) {
  //     const dataURL = signatureRef.current.toDataURL()
  //     setElement({
  //       ...element,
  //       dataURL,
  //     } as FormDataObject)
  //     onDataChange(props.id, dataURL)
  //     if (onDrawUpdate) onDrawUpdate(element)
  //   }
  // }, [element, signatureRef])

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.clientWidth)
    }
    // handleOnUpdate()
  }, [])

  useEffect(() => {
    if (!isLoaded && signatureRef && signatureRef.current && props.value) {
      signatureRef.current?.fromDataURL(props.value, {
        width,
        ratio: window.devicePixelRatio,
        height: HEIGHT,
      })
      setIsLoaded(true)
    }
  }, [value, signatureRef.current])

  const handleOnClear = () => {
    if (!props.readOnly && signatureRef.current) {
      signatureRef.current.clear()
      setValue(null)
      onDataChange(props.id, null)
    }
  }

  // const handleOnSave = useCallback(() => {
  //   if (signatureRef.current && signatureRef.current.toDataURL()) {
  //     const dataURL = signatureRef.current.toDataURL()
  //     setElement({
  //       ...element,
  //       dataURL,
  //     } as FormDataObject)
  //     console.log("DATA URL", dataURL)
  //     onDataChange(props.id, dataURL)
  //     if (onDrawEnd) onDrawEnd(element)
  //   }
  // }, [element, signatureRef.current])

  const handleOnSave = () => {
    if (signatureRef.current && signatureRef.current.toDataURL()) {
      const dataURL = signatureRef.current.toDataURL()
      setValue(dataURL)
      onDataChange(props.id, dataURL)
    }
  }

  return (
    <Box
      ref={containerRef}
      overflow={"hidden"}
      sx={{
        width: "100%",
        height: HEIGHT,
        backgroundColor: theme.palette.background.paper,
        cursor: "crosshair",
      }}
      borderRadius={2}
      border={`2px solid ${theme.palette.divider}`}>
      <Box bgcolor={theme.palette.background.paper} px={1.5} pt={1} display={"flex"} justifyContent={"space-between"}>
        <Typography color={theme.palette.text.secondary} typography={"p"}>
          {props.label}
        </Typography>
        <Button onClick={handleOnClear} size="small">
          Clear
        </Button>
      </Box>
      {width && (
        <SignatureCanvas
          dotSize={3}
          penColor={theme.palette.text.primary}
          ref={signatureRef}
          canvasProps={{ height: HEIGHT, width }}
          onEnd={handleOnSave}
        />
      )}
    </Box>
  )
}

export default SignaturePad
