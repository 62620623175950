import type { IUser } from "@utilisourcepackagelibdev/utilisourcepackagelib"
import { BASE_API_URL, WORKSPACE } from "../constants/commonStrings.constant"
import { apiService } from "./api.service"

class UserService {
  async login(onSuccess?: Function, onError?: Function) {
    return apiService({
      method: "post",
      url: BASE_API_URL + "/login",
      onSuccess,
      onError,
    })
  }

  async getAll(): Promise<IUser[]> {
    const url = BASE_API_URL + "/user"
    const response = await apiService.get(url, {
      withCredentials: false,
      params: {
        workspace: WORKSPACE,
      },
    })
    const { data }: { data: IUser[] } = response
    return data
  }

  async addUser(payload: IUser) {
    const url = BASE_API_URL + "/user"
    const response = await apiService.post(url, payload, {
      withCredentials: false,
    })

    const { data }: { data: IUser } = response
    return data
  }

  async updateUser(payload: IUser) {
    const url = BASE_API_URL + "/user"
    const response = await apiService.put(url, payload, {
      withCredentials: false,
    })

    const { data }: { data: IUser } = response
    return data
  }

  async updateUserSurvey(surveyId: number) {
    const url = BASE_API_URL + `/user/active-survey/${surveyId}`
    const response = await apiService.put(url, {
      withCredentials: false,
    })

    const { data }: { data: IUser } = response
    return data
  }

  async updateUserFeature(featureId: number) {
    const url = BASE_API_URL + `/user/active-feature/${featureId}`
    const response = await apiService.put(url, {
      withCredentials: false,
    })

    const { data }: { data: IUser } = response
    return data
  }

  async updateUserDevice(deviceId: number | null) {
    const url = BASE_API_URL + `/user/active-device/${deviceId}`
    const response = await apiService.put(url, {
      withCredentials: false,
    })

    const { data }: { data: IUser } = response
    return data
  }

  async getUser(id: string) {
    const url = BASE_API_URL + "/user/" + id
    const response = await apiService.get(url, {
      withCredentials: false,
    })

    const { data }: { data: IUser } = response
    return data
  }
}

export const userService = new UserService()
