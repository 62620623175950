import type { Feature, LineString, MultiLineString } from "@turf/helpers"

/**
 * Converts coordinates of a GeoJSON Feature from [lon, lat] to [lat, lon].
 * Supports LineString and MultiLineString geometries.
 *
 * @param {Feature<LineString | MultiLineString>} geojsonFeature - A GeoJSON Feature object.
 * @returns {number[][] | number[][][]} - Converted coordinates.
 */
export const convertCoordinates = (
  geojsonFeature: Feature<LineString | MultiLineString>,
): number[][] | number[][][] => {
  if (!geojsonFeature || !geojsonFeature.geometry || !geojsonFeature.geometry.coordinates) {
    throw new Error("Invalid GeoJSON Feature")
  }

  const { geometry } = geojsonFeature

  // Helper function to swap lon, lat to lat, lon
  const swapCoordinates = (coords: number[][]): number[][] => coords.map((coord) => [coord[1], coord[0]])

  if (geometry.type === "LineString") {
    // For LineString, swap each coordinate pair
    return swapCoordinates(geometry.coordinates as number[][])
  } else if (geometry.type === "MultiLineString") {
    // For MultiLineString, swap each coordinate pair in each line
    return (geometry.coordinates as number[][][]).map((line) => swapCoordinates(line))
  } else {
    throw new Error("Unsupported geometry type. Only LineString and MultiLineString are supported.")
  }
}
