import type { IFeature, IFeatureStatus, IFeatureStyled } from "../interfaces/feature.interface"
import type { OverridableStringUnion } from "@mui/types"
import type { BadgePropsColorOverrides } from "@mui/material"
import { green, red, grey } from "@mui/material/colors"
import { useEffect, useState } from "react"
import { HiOutlineCheckCircle, HiOutlineHandRaised, HiOutlinePlayCircle } from "react-icons/hi2"
import { HiOutlineSave } from "react-icons/hi"

export type TFeatureButtonStatusText = "completed" | "in progress" | "abandoned"

export interface IFeatureButtonStatus {
  text: TFeatureButtonStatusText
  color: OverridableStringUnion<"inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning">
  statusColor: OverridableStringUnion<
    "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning",
    BadgePropsColorOverrides
  >
  disabled: boolean
  readOnly: boolean
  bgColor: string
  startIcon?: JSX.Element
  actions: string[]
  status?: IFeatureStatus
}

const useFeatureButtonStatus = (feature?: IFeature | IFeatureStyled): IFeatureButtonStatus => {
  const [buttonStatus, setButtonStatus] = useState<IFeatureButtonStatus>({
    text: "in progress",
    status: "waiting",
    color: "success",
    bgColor: green[300],
    statusColor: "default",
    readOnly: true,
    disabled: false,
    startIcon: <HiOutlinePlayCircle />,
    actions: [],
  })

  useEffect(() => {
    setButtonStatus((data) => {
      switch (feature?.status) {
        // This means the feature is started
        case "started":
          return {
            text: "in progress",
            status: "started",
            color: "primary",
            readOnly: false,
            bgColor: green[500],
            statusColor: "success",
            disabled: false,
            startIcon: <HiOutlineSave />,
            actions: ["completed", "abandoned"],
          }
        case "paused":
        case "waiting":
          return {
            text: "in progress",
            status: "started",
            color: "primary",
            readOnly: false,
            bgColor: green[500],
            statusColor: "success",
            disabled: false,
            startIcon: <HiOutlineSave />,
            actions: ["started", "abandoned"],
          }
        case "abandoned":
          return {
            text: "abandoned",
            status: "abandoned",
            color: "error",
            readOnly: true,
            bgColor: red[500],
            statusColor: "error",
            disabled: true,
            startIcon: <HiOutlineHandRaised />,
            actions: ["started", "completed"],
          }
        case "completed":
          return {
            text: "completed",
            color: "success",
            readOnly: true,
            bgColor: grey[800],
            statusColor: "secondary",
            disabled: true,
            startIcon: <HiOutlineCheckCircle />,
            actions: ["abandoned", "started"],
          }
        default:
          return data
      }
    })
  }, [feature])

  return buttonStatus
}

export default useFeatureButtonStatus
