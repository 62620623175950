import type { BoxProps } from "@mui/material"
import { Box, Stack } from "@mui/material"
import type { ReactNode } from "react"
import React, { forwardRef, useEffect, useRef, useState } from "react"
import type { MapContainerProps } from "react-leaflet"
import { FeatureGroup, MapContainer, TileLayer } from "react-leaflet"
import { GeomanControls } from "react-leaflet-geoman-v2"
import type { Map, FeatureGroup as FeatureGroupRef } from "leaflet"
import { EsriProvider, GeoSearchControl } from "leaflet-geosearch"
import "leaflet-geosearch/dist/geosearch.css"

import MapCurrentLocationButton from "./MapCurrentLocationButton"
import "./MapRenderer.css"
import UserLocation from "../../Atoms/UserLocation/UserLocation.component"
import LayerControl, { GroupedLayer } from "./CustomLayerControls"
import MapCenterDragMarker from "./MapCenterDragMarker"
import MapDragEventActions from "./MapDragEventActions"

type MapRendererProps = MapContainerProps &
  BoxProps & {
    showCurrentLocationButton?: boolean
    secondaryButton?: ReactNode
    showControls?: boolean
    isDragging: boolean
    isSaving: boolean
    onSave: (template?: string) => void
    onDelete: () => void
    onCancel: () => void
    children?: any
  }

const MapRenderer = forwardRef<Map, MapRendererProps>(
  (
    {
      showControls,
      onSave,
      onCancel,
      onDelete,
      isDragging,
      isSaving,
      showCurrentLocationButton,
      secondaryButton,
      ...props
    },
    ref,
  ) => {
    const { zoom, children } = props
    const featureRef = useRef<FeatureGroupRef>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const [isMoving, setIsMoving] = useState(false)
    const initialLat = 39.8283
    const initialLng = -98.5795

    const handleOnSaveGeoJson = () => {
      if (featureRef && featureRef.current) {
        const data: string = JSON.stringify(featureRef.current.toGeoJSON())
        onSave(data)
      }
    }

    useEffect(() => {
      setTimeout(() => {
        const mapInstance: Map | null = (ref as React.MutableRefObject<Map | null>).current
        mapInstance?.invalidateSize(true)
        mapInstance?.on("movestart", () => setIsMoving(true))
        mapInstance?.on("moveend", () => setIsMoving(false))
      }, 100)
    })

    useEffect(() => {
      setTimeout(() => {
        const mapInstance: Map | null = (ref as React.MutableRefObject<Map | null>).current
        // Adding the GeoSearch control to the map
        if (mapInstance) {
          const provider = new EsriProvider()

          const searchControl = new GeoSearchControl({
            provider,
            style: "button", // 'button' or 'bar'
            showMarker: true,
            showPopup: false,
            resultFormat: ({ result }) => result.label,
            retainZoomLevel: false,
            animateZoom: true,
            autoClose: true,
            searchLabel: "Enter address",
            keepResult: true,
          })

          mapInstance.addControl(searchControl)
        }
      }, 100)
    }, [])

    return (
      <Box {...props} ref={containerRef} width={"100%"} height={"100%"} borderRadius={3} position={"relative"}>
        <MapCenterDragMarker isDragging={isDragging} />
        {!isMoving && (
          <MapDragEventActions
            isSaving={isSaving}
            isDragging={isDragging}
            onCancel={onCancel}
            onDelete={onDelete}
            onSave={onSave}
          />
        )}
        <MapContainer
          ref={ref}
          trackResize
          minZoom={1}
          center={[initialLat, initialLng]}
          zoom={zoom}
          style={{
            borderRadius: 3,
            width: "100%",
            height: "100%",
            overflow: "clip",
          }}
          scrollWheelZoom={true}>
          <LayerControl position="topright">
            <GroupedLayer checked name="Google Hybrid" group="Base Layers">
              <TileLayer url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}" id="google.hybrid" maxZoom={22} />
            </GroupedLayer>

            <GroupedLayer name="Google Satelite" group="Base Layers">
              <TileLayer url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" id="google.satellite" maxZoom={22} />
            </GroupedLayer>

            <GroupedLayer name="Open Street Map" group="Base Layers">
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={22} />
            </GroupedLayer>

            {React.Children.map(children, (child) => (
              <GroupedLayer
                name={child?.props.layerName ? child.props.layerName : "Default Name"}
                group="Feature Layers"
                checked>
                {child}
              </GroupedLayer>
            ))}
          </LayerControl>

          {showControls && (
            <FeatureGroup>
              <GeomanControls
                options={{
                  position: "topleft",
                  drawText: false,
                  rotateMode: false,
                  drawCircleMarker: false,
                }}
                globalOptions={{
                  continueDrawing: false,
                  editable: false,
                }}
                onMapRemove={() => handleOnSaveGeoJson()}
                onDrawEnd={() => handleOnSaveGeoJson()}
              />
            </FeatureGroup>
          )}

          {showCurrentLocationButton && <UserLocation />}
          <Stack
            gap={1}
            style={{
              position: "absolute",
              bottom: 20,
              right: 20,
              zIndex: 999,
            }}>
            {showCurrentLocationButton && <MapCurrentLocationButton />}
            {secondaryButton && secondaryButton}
          </Stack>
        </MapContainer>
      </Box>
    )
  },
)

export default MapRenderer
